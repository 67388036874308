/* Colours: darker #805ad5, lighter #d6bcfa */
/* CSS from: https://gist.github.com/baumandm/8665a34bc418574737847f7394f98bd9 */
.react-datepicker {
  font-size: 0.9rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 70%;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: #d6bcfa;
}

.react-datepicker__input-container:hover {
  border-color: #805ad5;
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border: 2px solid;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #cbd5e0;
}

.react-datepicker__navigation--next {
  border-left-color: #ffffff;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #cbd5e0;
}

.react-datepicker__header {
  background: #d6bcfa;
}

.react-datepicker__day:hover {
  background: #d6bcfa;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #805ad5;
  font-weight: normal;
}

.react-datepicker__triangle {
  visibility: hidden;
}
